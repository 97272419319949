import React from "react"
import logo from "./chicklogo.png"

function App() {
    return (
        <div>
            <img 
                style={{width: "20%"}}
                src={logo}></img>
            <br />
           <h3>The secret sauce the chicken was missing. </h3>
           <hr></hr>
           <br />
           In 2018, Chick-Fil-A was reported to have donated almost <strong>2 million dollars</strong> to charities known for a less than favorable view of the LGBT lifestyle. 
           <br />
           <br />
           In 2018, Chick-Fil-A reported an average of <strong>4.4 million dollars</strong> per store a year in sales across <strong>2,200 locations</strong>. So in 2018, Chick-Fil-A received around <strong>9.68 billion dollars</strong> from sales.
           <br />
           <br />
           This means that <strong>.02066% of sales</strong> to Chick-Fil-A could be seen as directly contributing to anti-LGBT causes. That comes down to about <strong>1/50th of a penny for every ten dollars</strong>. 
           <br />
           <br />
           So what if everytime you ate at Chick-Fil-A you changed the narrative? What if instead of passively donating to a wrong cause, you actively donated to a good one? 
           <br />
           <br />
           We all know love is 1000 times stronger than hate, so what if instead of complicitly giving 1/50th of a penny to poor causes every 10$ you spend on chicken, you gifted 20 cents towards a good one? 
           <br />
           <br />
            Every time you spend 50$ at Chick-Fil-A, donate <strong>just 1$</strong> to the Trevor Poject, and you will be proving that love really is 1000 times stronger than hate!
            <br />
            <br />    
            <a href="https://give.thetrevorproject.org/give/63307/#!/donation/checkout?c_src=website&c_src2=headerdonatebutton"><strong>Donate Here</strong></a>
        </div>
    )
    
}

export default App